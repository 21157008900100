var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<li class="FeedItem">\n  <a href="http://' +
__e( username ) +
'.' +
__e( account_host ) +
'" class="FeedItem-avatar">\n    <img src="' +
__e( avatar_url ) +
'" class="UserAvatar-img UserAvatar-img--big" />\n  </a>\n\n  <div class="MapCard MapCard--borderless MapCard--long js-card" data-vis-id="' +
__e( vis.id ) +
'">\n    <a href="' +
__e( base_url ) +
'/viz/' +
__e( vis.id ) +
'/public_map" class="MapCard-header js-header">\n      <div class="MapCard-loader js-loader"></div>\n    </a>\n\n    <div class="MapCard-content">\n      <div class="MapCard-contentBody">\n        <div class="MapCard-contentBodyRow MapCard-contentBodyRow--flex">\n          <h3 class="MapCard-title DefaultTitle CDB-Text is-semibold CDB-Size-large">\n            <a href="' +
__e( base_url ) +
'/viz/' +
__e( vis.id ) +
'/public_map" class="DefaultTitle-link u-ellipsLongText" title="' +
__e( vis.name ) +
'">' +
__e( vis.name ) +
'</a>\n          </h3>\n        </div>\n      </div>\n\n      <div class="MapCard-contentFooter CDB-Size-medium u-altTextColor">\n        <div class="MapCard-contentFooterDetails--left">\n          <div class="MapCard-contentFooterTimeDiff DefaultTimeDiff">\n            <i class="CDB-IconFont CDB-IconFont-clock DefaultTimeDiff-icon"></i>\n            ' +
__e( updated ) +
'\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</li>\n';

}
return __p
}