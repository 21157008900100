var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="IntermediateInfo IntermediateInfo--flex">\n  <div class="LayoutIcon">\n    <i class="CDB-IconFont CDB-IconFont-map"></i>\n  </div>\n  <h3 class="CDB-Text CDB-Size-large u-mainTextColor u-secondaryTextColor u-tSpace--m">' +
__e( name ) +
' doesn\'t have any public data yet</h3>\n</div>\n';

}
return __p
}