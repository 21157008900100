var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<li class="FeedItem">\n  <a href="http://' +
__e( username ) +
'.' +
__e( account_host ) +
'" class="FeedItem-avatar u-hideOnTablet">\n    <img src="' +
__e( avatar_url ) +
'" class="UserAvatar-img UserAvatar-img--big" />\n  </a>\n\n  <div class="DatasetCard DatasetCard--long" data-vis-id="' +
__e( vis.id ) +
'">\n    <div class="DatasetCard-content">\n      <div class="DatasetCard-contentBodyRow DatasetCard-contentBodyRow--flex">\n        <div class="DatasetCard-contentBodyDetails--left">\n          <div class="DatasetsList-itemCategory is--' +
__e( geomType ) +
'Dataset"></div>\n        </div>\n        <div class="DatasetCard-contentBodyDetails--right u-ellipsLongText">\n          <h3 class="DatasetCard-title DefaultTitle CDB-Text is-semibold">\n            <a href="' +
__e( base_url ) +
'/tables/' +
__e( vis.name ) +
'/public" class="DefaultTitle-link u-ellipsLongText" title="' +
__e( vis.name ) +
'">' +
__e( vis.name ) +
'</a>\n          </h3>\n\n          <div class="DatasetCard-contentFooter u-altTextColor CDB-Size-medium">\n            <div class="MapCard-contentFooterDetails--left">\n              ';
 if (datasetSize && datasetSize[0] > 0) { ;
__p += '\n              <div class="MapCard-contentFooterIcon u-hideOnMobile">\n                <i class="CDB-IconFont CDB-IconFont-floppy SizeIndicator-icon"></i>\n                <span class="MapCardIcon-counter">' +
__e( datasetSize[0] ) +
'</span> <span class="MapCardIcon-label">' +
__e( datasetSize[1] ) +
'</span>\n              </div>\n              ';
 } ;
__p += '\n\n              <div class="MapCard-contentFooterDetails--right">\n                <div class="MapCard-contentFooterTimeDiff DefaultTimeDiff">\n                  <i class="CDB-IconFont CDB-IconFont-clock DefaultTimeDiff-icon"></i>\n                  ' +
__e( updated ) +
'\n                </div>\n              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </li>\n';

}
return __p
}